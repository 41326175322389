<template>
    <el-form ref="formRef" :model="formodel" label-width="120px">
        <el-form-item label="权限名称">
            <el-input v-model="formodel.name" />
        </el-form-item>
        <el-form-item label="父权限">
            <el-tree-select placeholder="无" v-model="formodel.pid" node-key="id" :props="defaultProps" :data="permisList"
                :render-after-expand="false" />
        </el-form-item>
        <el-form-item label="权限类型">
            <el-radio-group v-model="formodel.ptype" size="large">
                <el-radio-button v-if="ptypeflg" text-color="#67c23a" label="页面" />
                <el-radio-button v-else type="warning" label="按钮" />
            </el-radio-group>
        </el-form-item>
        <el-form-item label="权限路径">
            <el-input v-model="formodel.paths" />
        </el-form-item> 
        <el-form-item label="页面值">
            <el-input v-model="formodel.frontPaths" />
        </el-form-item>
        <el-form-item>
            <el-button type="info" @click="clearForm">清空</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import { tr } from 'element-plus/lib/locale';

export default {
    data() {
        return {
            formodel: {
                name: '',//名称
                paths: '',//路径
                frontPaths:'',//页面路径/按钮值
                ptype: '页面',//页面/按钮
                pid: '', //父节点ID
                isDisable: 1
            },
            ptypeflg: true,
            permisList: [], //父节点
            defaultProps: {
                label: 'name'
            },
            name: ''
        }
    },
    created() {
        this.init();
    },
    methods: {
        initForm(item){
           this.init();
           this.formodel.pid=item.id;
        },
        init() {
            const _this = this;
            this.$https.get('api/admin/Permission/GetPertreeModel').then(res => {
                if (res.success) {
                    _this.permisList = res.data;
                }
            }).catch(erro => {
                ElMessage({
                    message: '数据异常',
                    type: 'error'
                })
            })
        },
        onSubmit() {
            const _this = this;
            this.formodel.pid = (this.formodel.pid == '' ? 0 : this.formodel.pid);
            this.$https.post('api/Admin/permission/insertOrUpdate', this.formodel).then(res => {
                if (res.success) {
                    _this.clearForm()
                    _this.$emit('closeDialog');
                }
            })
        },
        //清空
        clearForm() {
            this.formodel.paths=''
            this.formodel.name=''
            this.formodel.pid=''
            this.formodel.isDisable=1
            this.formodel.frontPaths=''
        }
    }
}
</script>