<template>
    <div>
        <el-row class="hz-row" :gutter="24">
            <el-col :span="12">
                <el-button type="primary" @click="addDialog">
                    <el-icon style="vertical-align: middle">
                        <Plus />
                    </el-icon>
                    <span style="vertical-align: middle"> 新增权限 </span>
                </el-button>
            </el-col>
            <el-col :span="4">
            </el-col>
            <el-col :span="8">

            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border>
            <el-table-column prop="name" label="名称" />
            <el-table-column prop="typeName" label="类型">
                <template #default="scope">
                    <el-tag :type="scope.row.typeName === '页面' ? 'success' : 'warning'" disable-transitions>{{
                        scope.row.typeName }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="paths" label="路径"  />   
            <el-table-column prop="frontPaths" label="前端值"  />
            <el-table-column prop="createTime" label="创建时间"  />
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="small" type="primary" v-if="(scope.row.parentId == '0' ? true : false)"
                        @click="addDialog(scope.row)">新增子节点</el-button>
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div>
        <el-dialog v-model="dialogVisible" :title="dialogTitle" width="30%" draggable>
            <Add @closeDialog="closeDialog" ref="addchild" v-if="dialogTitle == '新增权限'"></Add>
            <Edit v-else-if="dialogTitle == '编辑权限'" @closeDialog="closeDialog" ref="editPemis"></Edit>
        </el-dialog>
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import Add from './add.vue'
import Edit from './edit.vue'
export default {
    data() {
        return {
            tableData: [],
            dialogVisible: false,
            dialogTitle: '新增权限'
        }
    },
    created() {
        this.init();
    },

    methods: {
        init() {
            this.$https.get('api/admin/Permission/GetPertreeModel').then(res => {
                if (res.success) {
                    this.tableData = res.data;
                }
            }).catch(erro => {
                ElMessage({
                    message: '数据异常',
                    type: 'error'
                })
            })
        },
        handleDelete(index, item) {
            const _this = this;
            this.$https.get('api/admin/Permission/del', { params: { id: item.id } }).then(res => {
                if (res.success) {
                    ElMessage({
                        type: 'success',
                        message: res.msg
                    })
                    _this.init();
                }
            })
        },
        addDialog(item) {
            this.dialogTitle = "新增权限"
            this.dialogVisible = true;
            if (item.id) {
                this.$nextTick(() => {
                    this.$refs.addchild.ptypeflg = false;
                    this.$refs.addchild.formodel.ptype = '按钮';
                    this.$refs.addchild.initForm(item)
                })
            } else {
                this.$nextTick(() => {
                    this.$refs.addchild.ptypeflg = true;
                    this.$refs.addchild.formodel.ptype = '页面';
                })
            }
        },
        handleEdit(index, item) {
            this.dialogTitle = "编辑权限"
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.editPemis.formValue(item);
            })
        },
        closeDialog() {
            this.dialogVisible = false;
            this.init();
        }
    },
    components: {
        Add,
        Edit
    }
}

</script>

